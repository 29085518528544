import axios from "axios";
//import https from "https";
//import * as fs from "fs";

const gateway_port = process.env.REACT_APP_API_PORT || 5000;
const gateway_protocol = process.env.REACT_APP_PROTOCOL || "https";
const api_hostname = process.env.REACT_APP_API_HOSTNAME || window.location.hostname;
//const url = "https://" + window.location.hostname + ":" + gateway_port;
//const url =  gateway_protocol + "://" + window.location.hostname + ":" + gateway_port;


const url = gateway_protocol + "://" + api_hostname + ":" + gateway_port

console.log(
  "ENV : " + process.env
);

console.log(
  "Axios URL : " + url + " EnvPort:" + process.env.REACT_APP_API_PORT
);

const instance = axios.create({
  baseURL: url,
  timeout: 20000, // 20 sec
});

export default instance;
